import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  GatsbyImage,
  getImage,
  StaticImage,
  withArtDirection,
} from 'gatsby-plugin-image'

interface IMenuBackgroundImage {}

const imageStyles = {
  position: 'fixed',
  left: 0,
  right: 0,
  width: 'inherit',
  height: 'inherits',
  objectPosition: 'center',
}

const MenuBackgroundImage: React.FC<IMenuBackgroundImage> = () => {
  // Queries the image files from src/assets/images/menu
  const query = graphql`
    query {
      allFile(filter: { relativeDirectory: { regex: "/(images)/(menu)/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `
  const {
    allFile: { nodes },
  } = useStaticQuery(query)

  const images =
    nodes.length > 1
      ? withArtDirection(getImage(nodes[0]), [
          {
            media: `(max-width: 48em)`,
            image: getImage(nodes[1]),
          },
        ])
      : undefined

  /* Note: Gatsby requires using [gatsby-plugin-image] 
    components when working with images (jpg, png, webp). 
    See README.md "Gotchas" section for more details
  */
  return images ? (
    <GatsbyImage
      image={images}
      className="menu_background-image"
      alt="Nilly's Cheeseburgers"
    />
  ) : (
    <StaticImage
      src="../../../assets/images/menu/nillys_burgers_portrait_img.jpg"
      className="menu_background-image"
      alt="Nilly's Cheeseburgers"
    />
  )
}

export default memo(MenuBackgroundImage)
