const MENU_ITEMS = {
  BURGERS: [
    {
      name: 'SINGLE',
      description: `IN-HOUSE, FRESH GROUND BEEF, YELLOW AMERICAN CHEESE, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 7.5,
      type: 'main',
    },
    {
      name: 'DOUBLE',
      description: `IN-HOUSE, FRESH GROUND BEEF x2, YELLOW AMERICAN CHEESE x2, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 10,
      type: 'main',
    },
    {
      name: 'ADD CHILI',
      priceAdjustment: 2,
      type: 'sub',
    },
    {
      name: 'SUB IMPOSSIBLE MEAT',
      priceAdjustment: 3,
      type: 'sub',
    },
    {
      name: 'GLUTEN-FREE BUN AVAILABLE UPON REQUEST',
      priceAdjustment: 3,
      type: 'sub',
    },
  ],
  'HOT DOGS': [
    {
      name: 'REGULAR',
      description: 'ONION SAUCE, MUSTARD, SCRATCH RELISH',
      price: 6,
      type: 'main',
    },
    {
      name: 'CHILI DOG',
      description: 'HOUSE CHILI, MUSTARD, ONIONS',
      price: 8,
      type: 'main',
    },
    {
      name: 'CHILI CHEESE DOG',
      price: 9,
      description: 'HOUSE CHILI, AMERICAN CHEESE, MUSTARD, ONIONS',
      type: 'main',
    },
  ],
  'FRIES & OTHERS': [
    {
      name: 'CRINKLE CUT FRIES',
      description: 'POTATO FRIES COOKED TO GOLDEN CRISP',
      price: 4,
      type: 'main',
    },
    {
      name: 'CHILI CHEESE FRIES',
      description:
        'POTATO FRIES, HOUSE CHILI, AMERICAN CHEESE, ONIONS, HOUSE PICKLES',
      price: 0,
      smPrice: 7,
      lgPrice: 13,
      type: 'main',
    },
    {
      name: 'GRILLED CHEESE',
      description: `YELLOW AMERICAN CHEESE x2, ONIONS, SCRATCH PICKLES, YELLOW MUSTARD, IN BETWEEN MARTIN'S POTATO ROLL`,
      price: 4,
      type: 'main',
    },
    {
      name: 'RANCH',
      price: 1.5,
      type: 'extra',
    },
    {
      name: 'CUP OF PICKLES',
      price: 2,
      type: 'extra',
    },
  ],
  DRINKS: [
    {
      name: 'MEXICAN SODA',
      price: 4,
    },
    {
      name: 'CANNED SODA',
      price: 2.5,
    },
    {
      name: 'SPARKLING WATER',
      price: 3,
    },
    {
      name: 'BOTTLED WATER',
      price: 2,
    },
    {
      name: 'SHAKES',
      description: 'CHOCOLATE, STRAWBERRY OR VANILLA',
      price: 7,
    },
  ],
}

export default MENU_ITEMS
