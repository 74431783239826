import { Box, Grid, Text } from '@chakra-ui/react'
interface IMenuItem {
  name: string
  price: number
  priceAdjustment: number
  smPrice?: number
  lgPrice?: number
  description?: string
  type?: 'main' | 'sub' | 'extra'
}

const formatPrice = (num: number, noDecimal?: boolean) => {
  const fixedDecimalPlaces = noDecimal ? 0 : 1
  return `$${num.toString().length > 1 ? num.toFixed(fixedDecimalPlaces) : num}`
}

const MenuItem = ({
  name,
  description,
  price,
  smPrice,
  lgPrice,
  priceAdjustment,
  type,
}: IMenuItem) => {
  // Get item font size
  let itemSize = 'md'
  if (type === 'main') itemSize = 'xl'
  if (type === 'sub' || type === 'extra') itemSize = 'sm'

  // Get price text to be displayed
  let priceText = '--'
  if (price) priceText = formatPrice(price)
  if (priceAdjustment)
    priceText = `${priceAdjustment > 0 ? '+$' : '-$'}${Math.abs(
      priceAdjustment
    )}`

  if (smPrice && lgPrice) {
    priceText = formatPrice(smPrice, true)
    priceText += `/${formatPrice(lgPrice, true)}`
  }

  return (
    <Box width="100%">
      <Grid
        textStyle="h3"
        fontSize={itemSize}
        fontWeight="normal"
        gridAutoFlow="column"
        gridTemplateColumns="1fr 5rem"
        gridColumnGap={2}
        width="100%"
      >
        <Text fontSize="1em">{name}</Text>
        <Text fontSize="1em">{priceText}</Text>
      </Grid>
      {description && (
        <Text
          fontSize="xs"
          fontWeight="light"
          width="80%"
          maxWidth="30rem"
          mt={0.6}
          mb={2}
        >
          {description}
        </Text>
      )}
    </Box>
  )
}

export default MenuItem
