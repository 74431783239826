import React from 'react'
import Layout from '@/components/Layout'
import Menu from '@/components/pages/Menu'

export default () => {
  return (
    <Layout
      title="Nilly's Burgers: Menu"
      metaDescription="We pride ourselves on what gets made around here. Try our delicious homestyle burgers - prepared in-house and topped with our special Scratch Pickles."
      hideHeaderBoxShadow
      hideFooter
    >
      <Menu />
    </Layout>
  )
}
