import { Button } from '@/components'
import MENU_ITEMS from '@/data/menu-items'
import { DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { memo, useEffect } from 'react'
import MenuBackgroundImage from './MenuBackgroundImage'
import MenuItem from './MenuItem'

interface IMenu {}

const Menu: React.FC<IMenu> = () => {
  const categories = Object.keys(MENU_ITEMS)

  // Turn off overscroll bounce behavior
  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html')
    if (htmlEl.length) {
      htmlEl[0].style.overscrollBehaviorY = 'none'
    }
  }, [])

  return (
    <Grid
      id="menu"
      width="100%"
      height="inherit"
      minHeight="inherit"
      maxWidth="100%"
      overflowX="hidden"
      overscrollBehaviorY="none"
    >
      <Box gridArea="1/1" height="inherit" width="inherit">
        <MenuBackgroundImage />
      </Box>
      <Box
        justifySelf="flex-end"
        gridArea="1/1"
        width={['100%', '100%', '40%']}
        height="100%"
        px={[5, 8, 6]}
        py={4}
        bg={useColorModeValue(
          'light.container.translucentBackground',
          'dark.container.translucentBackground'
        )}
        color={useColorModeValue('light.container.text', 'dark.container.text')}
        zIndex="2"
      >
        <Box textAlign={['center', 'center', 'left']} justifySelf="center">
          <Flex
            justifyContent={['center', 'center', 'start']}
            alignItems="center"
          >
            <Text
              as="h1"
              textStyle="h2"
              fontSize={['2xl', '3xl']}
              textTransform="uppercase"
            >
              Nilly's Menu
            </Text>
            <Link
              href="../../../../nillys-menu.pdf"
              color={useColorModeValue(
                'light.container.text',
                'dark.container.text'
              )}
              download
              _hover={{ color: 'primary.main' }}
              _active={{ color: 'primary.main' }}
              _focus={{ color: 'primary.main' }}
            >
              <Icon
                as={DownloadIcon}
                fontSize={['xl', '2xl']}
                ml={2}
                mb={1.5}
              />
            </Link>
          </Flex>

          <Link
            href="https://www.toasttab.com/nillysburgers/v3"
            target="_blank"
            _hover={{ textDecoration: 'none' }}
          >
            <Button height="2.2rem" width={['100%', '14rem']} my={[2, 1]}>
              <Text
                textStyle="h3"
                textTransform="uppercase"
                fontSize="1.4rem"
                fontWeight="500"
              >
                order now
              </Text>
            </Button>
          </Link>
        </Box>

        <Stack justifySelf="center" pb={5}>
          {categories.map((category) => {
            return (
              <section key={nanoid()}>
                <Divider
                  orientation="horizontal"
                  my={4}
                  borderColor={useColorModeValue(
                    'light.container.text',
                    'dark.container.text'
                  )}
                  opacity={0.2}
                />
                <Text
                  as="h2"
                  textStyle="h3"
                  fontSize={['2xl', '3xl']}
                  fontWeight="bold"
                  textAlign={['center', 'center', 'left']}
                  mb={6}
                >
                  {category}
                </Text>
                <Stack spacing={2}>
                  {MENU_ITEMS[category].map((item) => (
                    <MenuItem key={nanoid()} {...item} />
                  ))}
                </Stack>
              </section>
            )
          })}
        </Stack>
      </Box>
    </Grid>
  )
}

export default memo(Menu)
